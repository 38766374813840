<template>
  <v-app>
    <v-main
      :class="rtl ? 'mainAr' : 'main'"
      style="letter-spacing: 0 !important"
    >
      <router-view />
      <!-- <dashboard-core-footer /> -->
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Auth",
  backgroundImag: require("@/assets/dental.jpg"),
  components: {},

  computed: {
    rtl() {
      return this.$vuetify.rtl;
    },
  },
  data: () => ({
    expandOnHover: false,
  }),
};
</script>
<style>
.mainAr::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/login.jpg");
  background-size: cover;
  background-position: center;

  z-index: 0;
}

.main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/login.jpg");
  background-size: cover;
  background-position: center;
  transform: scaleX(-1);

  z-index: 0;
}
</style>
